import { createTheme } from '@mui/material/styles'
import { createBreakpoints } from '@mui/system'
import { Poppins } from 'next/font/google'

const breakpoints = createBreakpoints({})

export const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['sans-serif']
})

const theme = createTheme({
  typography: {
    fontFamily: poppins.style.fontFamily,
    h1: {
      fontSize: 24,
      fontWeight: 600,
      [breakpoints.up('md')]: {
        fontSize: 40,
        lineHeight: '1.4'
      }
    },
    h2: {
      fontSize: 32,
      fontWeight: 600
    },
    h3: {
      fontSize: 20,
      fontWeight: 600
    },
    h4: {
      fontSize: 18,
      fontWeight: 600
    }
  },
  palette: {
    primary: {
      main: '#34C85A'
    },
    secondary: {
      main: '#ff4940'
    }
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        filled: {
          padding: '16.5px 14px'
        },
        root: {
          border: '1px solid #ededed',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #34C85A'
          },
          '&:hover': {
            outline: 'none',
            border: '1px solid #ededed',
            backgroundColor: '#FFFFFF'
          }
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          border: '1px solid #ededed',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #34C85A'
          },
          '&.Mui-error': {
            border: '1px solid #ff441f'
          },
          '&:hover': {
            backgroundColor: '#FFFFFF'
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: '2px solid',
            '&:hover': {
              border: '2px solid'
            }
          }
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: '#FFFFFF'
          }
        }
      ],
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: 8
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: {
            variant: 'filled',
            color: 'primary'
          },
          style: {
            color: '#FFFFFF'
          }
        }
      ]
    }
  }
})

export default theme
