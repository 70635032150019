import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  otpInput: {
    width: 32.5,
    height: 40,
    textAlign: 'center',
    border: '1px solid #E6EBF1',
    fontSize: 16,
    fontWeight: 700,
    color: '#868D97',
    borderRadius: 8,
    outlineColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      width: 42.75,
      height: 56
    }
  },
  otpInputError: {
    border: '1px solid #FF2525'
  }
}))
