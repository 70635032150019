import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  verificationCodeTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#000',
    textAlign: 'center',
    lineHeight: '48px',
    [theme.breakpoints.up('md')]: {
      fontSize: 32
    }
  },
  verificationCodeSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center'
  },
  verificationCodeForm: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content'
  },
  otpBox: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gap: 8,
    padding: '10px 0px 10px 0px'
  },
  otpErrorMessage: {
    textAlign: 'center',
    color: '#FF2525',
    fontSize: 10,
    fontWeight: 700
  },
  resendButton: {
    fontWeight: 600,
    justifySelf: 'flex-end',
    color: `${theme.palette.primary.main} !important`,
    width: 'max-content !important',
    marginTop: 8,
    padding: '0 !important',
    '&:disabled': {
      backgroundColor: 'transparent !important',
      color: '#000 !important',
      border: 0
    }
  },
  agreement: {
    fontSize: '12px !important',
    color: '#7F808B !important',
    marginTop: 32,
    '& a': {
      color: 'inherit !important'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px !important'
    }
  },
  submitButton: {
    height: 40,
    marginTop: 24,
    fontSize: '14px !important',
    fontWeight: '600 !important',
    '&:disabled': {
      opacity: 1,
      color: '#9E9E9F',
      background: '#EDEDED',
      border: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      height: 56,
      fontSize: '16px !important'
    }
  },
  backButton: {
    color: theme.palette.primary.main,
    height: 'auto !important',
    padding: '0 !important',
    marginTop: 16,
    fontSize: '14px !important',
    fontWeight: '600 !important',
    [theme.breakpoints.up('sm')]: {
      height: 56,
      marginTop: 24,
      fontSize: '16px !important'
    }
  }
}))
