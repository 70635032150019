import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import Link from 'next/link'

import useStyles from './LinkBox.styled'

export default function LinkBox({ columns }) {
  const { classes } = useStyles()

  return (
    <Box component='section' alignSelf='flex-end' className={classes.container}>
      <Box className={classes.linkBoxContainer}>
        <Grid container direction='row'>
          {columns?.map((col) => {
            return (
              <Grid key={col._key} item md={3} xs={6}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItem>
                    <Typography variant='h4' className={classes.headerText}>
                      {col.title}
                    </Typography>
                  </ListItem>

                  {col.linkList.map((link) => (
                    <ListItem key={link._key}>
                      <Link
                        href={link.url}
                        replace
                        target='_blank'
                        color='#6F6F70'
                        style={{ textDecoration: 'none' }}
                        locale=''
                      >
                        <Typography className={classes.text}>{link.label}</Typography>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}
