import { SectionComponents } from '@/constants/SectionComponets'
import { parseAndCreateElements } from '@/utils/parseAndCreateElements'
import { Button } from '@mui/material'
import Head from 'next/head'
import Layout from '../layout/Layout'
import BreadcrumbSchema from '../breadcum-schema/breadcum-schema'

export default function Page({ data, hreflangs, menuData, footerData, isPreview }) {
  const isIframe = typeof window !== 'undefined' && window.self !== window.top

  return (
    <>
      <Head>
        <title>{data?.seoTitle}</title>
        <meta name='description' content={data?.seoDescription} />
        {data?.seoCanonical && <link rel='canonical' href={data?.seoCanonical?.current} />}
        {process.env.NEXT_PUBLIC_ENV === 'production' ? (
          data?.seoRobots && <meta name='robots' content={data?.seoRobots} />
        ) : (
          <meta name='robots' content='noindex,nofollow' />
        )}
        {data?.ogTitle && <meta property='og:title' content={data?.ogTitle} />}
        {data?.ogDescription && <meta property='og:description' content={data?.ogDescription} />}
        {data?.ogType && <meta property='og:type' content={data?.ogType} />}
        {data?.ogUrl && <meta property='og:url' content={data?.ogUrl} />}
        {data?.ogImage && <meta property='og:image' content={data?.ogImage} />}
        {data?.ogSiteName && <meta property='og:site_name' content={data?.ogSiteName} />}
        {data?.ogLocale && <meta property='og:locale' content={data?.ogLocale} />}
        {data?.ogAudio && <meta property='og:audio' content={data?.ogAudio} />}
        {data?.ogVideo && <meta property='og:video' content={data?.ogVideo} />}
        {data?.customHead?.code && parseAndCreateElements(data?.customHead.code)}
        {hreflangs?.map(({ locale, href }) => {
          if (!(locale || href)) return null
          return <link key={href} rel='alternate' hrefLang={locale} href={href} />
        })}
      </Head>
      {data?.customBody?.code && <div dangerouslySetInnerHTML={{ __html: data?.customBody?.code }} />}

      <BreadcrumbSchema />

      <Layout hreflangs={hreflangs} menuData={menuData} footerData={footerData}>
        {data?.sectionList?.map((section) => {
          const sectionData = { ...section }
          const SectionComponent = SectionComponents[section._type].component
          if (!SectionComponent) return null
          return <SectionComponent key={section._key} {...sectionData} />
        })}
        {isPreview && !isIframe && (
          <Button
            variant='contained'
            size='large'
            href={'/api/exit-preview'}
            sx={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 10000000000 }}
          >
            Exit preview mode
          </Button>
        )}
      </Layout>
    </>
  )
}
