import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  container: {
    padding: '60px 24px',
    borderTop: '1px solid #E6E6E6',
    background: '#FFF',
    [theme.breakpoints.up('md')]: {
      padding: '60px 50px'
    }
  },
  linkBoxContainer: {
    width: '100%',
    maxWidth: 1280,
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 40
  },
  headerText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#000000',
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    }
  },
  text: {
    fontSize: 12,
    color: '#6F6F70'
  }
}))
