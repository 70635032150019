import { backgroundColorField, imageField, textField } from '@/defaultSchema/fields'
import { defineType } from 'sanity'

export default defineType({
  type: 'object',
  name: 'bannerWithCTAs',
  title: 'BannerWithCTAs',
  groups: [
    {
      name: 'images',
      title: 'Images'
    }
  ],
  initialValue: {
    fullHeight: false,
    withSignupForm: false,
    positionTitle: 'left'
  },
  fields: [
    textField(),
    {
      type: 'array',
      name: 'ctas',
      title: 'Call to Actions',
      of: [
        {
          type: 'object',
          name: 'ctaItem',
          title: 'CTA Item',
          fields: [
            {
              type: 'string',
              name: 'text',
              title: 'CTA Text',
              description: 'The text that will be displayed inside the CTA.'
            },
            {
              type: 'url',
              name: 'link',
              title: 'CTA Link',
              description: 'The URL the CTA will point to.'
            },
            {
              type: 'string',
              name: 'gtmEvent',
              title: 'GTM Event',
              description: 'The custom event string to be triggered in GTM.'
            },
            backgroundColorField
          ]
        }
      ]
    },
    {
      type: 'boolean',
      name: 'fullHeight',
      title: 'full height',
      description: 'Select true if you want the banner to be 100% of the screen height'
    },
    {
      type: 'boolean',
      name: 'hasShadowOverlay',
      title: 'Has Shadow Overlay',
      description: 'Select true if you want the image to have Overlay '
    },
    backgroundColorField,
    imageField(),
    {
      type: 'image',
      name: 'backgroundImage',
      group: 'images',
      title: 'Background Image css',
      options: {
        hotspot: true
      },
      fields: [
        {
          type: 'image',
          name: 'backgroundImageMobile',
          group: 'images',
          title: 'Background Image mobile css',
          options: {
            hotspot: true
          }
        }
      ]
    }
  ],
  preview: {
    select: {
      backgroundImage: 'backgroundImage',
      image: 'image'
    },
    prepare({ backgroundImage, image }) {
      return {
        media: backgroundImage || image,
        title: 'BannerWithCTAs'
      }
    }
  }
})
