import RappiIcon from '@/icons/RappiIcon'
import { MenuRounded } from '@mui/icons-material'
import { AppBar, Box, Breadcrumbs, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import CountrySelector from '../country-selector/CountrySelector'
import ItemLinkButton from '../item-link-button/ItemLinkButton'
import SubItemsLink from '../sub-items-link/SubItemsLink'
import useStyles from './Header.styles'

export default function Header({ openSidebar, focusFirstInput, hreflangs, menuData }) {
  const { classes } = useStyles()
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('1280'))
  const { asPath, push } = useRouter()
  const [selectedSubItem, setSelectedSubItem] = React.useState()
  const [visibleBreadcrumb, setVisibleBreadcrumb] = React.useState(true)

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setVisibleBreadcrumb(false)
      } else {
        setVisibleBreadcrumb(true)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const breadcrumbs = asPath
    .split('?')[0]
    .split('/')
    .filter((item) => item)

  const formatText = (text) => {
    return text
      .toLowerCase()
      .split('-')
      .map((palabra, index) => (index === 0 ? palabra.charAt(0).toUpperCase() + palabra.slice(1) : palabra))
      .join(' ')
  }

  return (
    <AppBar position='fixed' color='primary' className={classes.appBarRoot}>
      <Grid container justifyContent='space-between' alignItems='center' className={classes.wrapper}>
        <div className={classes.appBarActions}>
          {!isDesktop && (
            <MenuRounded
              htmlColor='#6A696E'
              onClick={() => {
                openSidebar()
              }}
            />
          )}
          <Link href='/' style={{ display: 'flex', alignItems: 'center' }}>
            <RappiIcon height={!isDesktop ? '24' : '48'} width={!isDesktop ? '56' : '113'} />
          </Link>
        </div>
        {isDesktop && (
          <>
            <nav className={classes.appBarActions}>
              {menuData?.items.map((item) => {
                if (item?.hasSubItems) {
                  return (
                    <SubItemsLink
                      key={item._key}
                      item={item}
                      setSelectedSubItem={setSelectedSubItem}
                      selectedSubItem={selectedSubItem}
                    />
                  )
                }

                return <ItemLinkButton item={item} key={item._key} setSelectedSubItem={setSelectedSubItem} />
              })}
            </nav>

            {asPath.split('?')[0] !== '/' && (
              <Box component='section' className={`${classes.breadcrumbs} ${!visibleBreadcrumb ? classes.hidden : ''}`}>
                <Breadcrumbs aria-label='breadcrumb' separator='>'>
                  <Link underline='hover' color='inherit' href='/'>
                    <Typography color='text.primary' fontWeight={700}>
                      <FormattedMessage id={'landingPage.header.restaurant'} />
                    </Typography>
                  </Link>

                  {breadcrumbs.map((breadcrumb, index) => {
                    return index === breadcrumbs.length - 1 ? (
                      <Typography color='text.primary' fontWeight={700} key={index}>
                        {formatText(breadcrumb)}
                      </Typography>
                    ) : (
                      <Link underline='hover' color='inherit' href={`/${breadcrumb}`} key={index}>
                        <Typography color='text.primary' fontWeight={700} key={index}>
                          {breadcrumb.includes('blog') ? (
                            <FormattedMessage id={'landingPage.header.restaurant.blog'} />
                          ) : (
                            formatText(breadcrumb)
                          )}
                        </Typography>
                      </Link>
                    )
                  })}
                </Breadcrumbs>
              </Box>
            )}
          </>
        )}
        <div className={classes.appBarActions}>
          {isTablet && <CountrySelector hreflangs={hreflangs} />}
          {asPath.split('?')[0] !== '/' && (
            <>
              {isTablet && <div className={classes.divider}></div>}
              <Button
                LinkComponent={Link}
                id='loginButtonId'
                variant='outlined'
                href={`${process.env.NEXT_PUBLIC_URL_PARTNERS}/login`}
                className={classes.appBarButton}
              >
                <FormattedMessage id={'selfOnboardingPage.landingPage.header.enter'} />
              </Button>
              <Button
                disableElevation
                variant='contained'
                className={classes.appBarButton}
                onClick={() => {
                  if (asPath === '/') {
                    focusFirstInput()
                    scrollTo({ top: 0 })
                    return
                  }

                  push('/')
                }}
              >
                <FormattedMessage id={'selfOnboardingPage.landingPage.header.signup'} />
              </Button>
            </>
          )}
        </div>
      </Grid>
    </AppBar>
  )
}
