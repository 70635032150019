/**
 * Generates a query string from a params object.
 * @param params
 * @returns {string}
 */
function generateQueryString(params) {
  if (!params) return ''

  const urlParams = Object.keys(params)
    .filter(function (param) {
      return params[param] != null && params[param].toString().length
    })
    .map(function (param) {
      return param + '=' + params[param]
    })
    .join('&')

  return '?' + urlParams
}

export const isUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

export default generateQueryString
