import urlFor from '@/lib/urlFor'
import { sendTagManager } from '@/utils/tagManager'
import { ChevronRightOutlined } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import * as React from 'react'
import Text from '../text/Text'

export default function BannerWithCTAs({
  text,
  ctas,
  positionTitle,
  fullHeight,
  backgroundColor,
  backgroundImage,
  backgroundImageMobile,
  minHeight = '400px',
  hasShadowOverlay = false,
  backgroundPosition = 'top'
}) {
  const backgroundImageMobileUrl = urlFor(backgroundImageMobile)
  const backgroundImageUrl = urlFor(backgroundImage)
  const overlay = hasShadowOverlay ? 'linear-gradient(rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),' : ''

  return (
    <Grid
      container
      component='section'
      sx={(theme) => ({
        width: '100%',
        minHeight: fullHeight ? 'calc(100vh - 64px)' : minHeight,
        padding: '40px 24px',
        backgroundColor: backgroundColor ? backgroundColor.hex : 'transparent',
        background: backgroundImageMobileUrl
          ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageMobileUrl})`
          : backgroundImageUrl
          ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageUrl})`
          : '',
        [theme.breakpoints.up('sm')]: {
          background: backgroundImageUrl
            ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageUrl})`
            : ''
        },
        [theme.breakpoints.up('md')]: {
          padding: '60px 50px'
        }
      })}
    >
      <Grid
        maxWidth={1280}
        sx={(theme) => ({
          width: '100%',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoRows: 'max-content',
          gap: '20px',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
            alignContent: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '40px'
          },
          [theme.breakpoints.up('xs')]: {
            alignContent: 'flex-end'
          }
        })}
      >
        <Grid display='grid' gap='20px'>
          {text && <Text blockContent={text} />}
        </Grid>

        {ctas && (
          <Grid display='grid' sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            {ctas.map((cta) => {
              return (
                <Grid
                  key={cta._key}
                  sx={(theme) => ({
                    display: 'grid',
                    paddingBlockEnd: '20px',
                    [theme.breakpoints.up('md')]: {
                      order: positionTitle === 'right' ? '-1' : 'initial',
                      maxWidth: '520px'
                    }
                  })}
                >
                  <Button
                    size='large'
                    href={cta.link}
                    sx={{
                      width: '100%',
                      height: '56px',
                      fontSize: '16px'
                    }}
                    variant='contained'
                    onClick={() => {
                      if (cta.gtmEvent) {
                        sendTagManager(cta.gtmEvent)
                      }
                    }}
                  >
                    {cta.text}
                    <div style={{ position: 'absolute', right: 0, marginRight: '12px', writingMode: 'vertical-rl' }}>
                      <ChevronRightOutlined />
                    </div>
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
