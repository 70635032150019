import * as Yup from 'yup'

import { countryCode } from '@/constants/CountryCode'
import { PHONE_LENGHTS, phonePrefixes } from '@/constants/PhoneLenghts'
import {
  minCharacters,
  minLetters,
  minNumbers,
  minSpecialCharacters,
  nameValidation,
  onlyNumbers
} from '@/utils/inputsValidation'
import SelfOnboardingService from '@/services/SelfOnboardingService'

const selfOnboardingService = new SelfOnboardingService()

export default function buildValidationSchema() {
  const requiredMsg = 'selfOnboardingPage.landingPage.form.required'

  return Yup.object().shape({
    name: Yup.string().matches(nameValidation, 'selfOnboardingPage.landingPage.form.invalid').required(requiredMsg),
    lastname: Yup.string().matches(nameValidation, 'selfOnboardingPage.landingPage.form.invalid').required(requiredMsg),
    phonePrefix: Yup.string().required(requiredMsg),
    phone: Yup.string()
      .required(requiredMsg)
      .matches(onlyNumbers, 'selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.error.invalid')
      .when('phonePrefix', (prefix, schema) => {
        const countryPrefix = prefix[0].split('|')
        prefix = countryPrefix[0]
        if (!prefix) return schema
        const countryCodePrefix = phonePrefixes.find((p) => p.value === prefix)?.country
        const { min, max } = PHONE_LENGHTS[countryCodePrefix]

        let messageError =
          min === max
            ? {
                id: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.error.exactly',
                values: { number: min }
              }
            : {
                id: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.error.between',
                values: { min, max }
              }

        if (countryCodePrefix === countryCode.ARGENTINA)
          messageError = 'selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.error.invalid.AR'

        return schema.test('leng', messageError, (value) => {
          return value?.length >= min && value?.length <= max
        })
      }),
    email: Yup.string()
      .email('selfOnboardingPage.landingPage.hero.createAccountForm.field.mail.invalid')
      .required(requiredMsg)
      .test(
        'email-domain',
        'selfOnboardingPage.landingPage.hero.createAccountForm.field.mail.unauthorized',
        (value) => {
          return !/[@]rappi[.]com$/.test(value)
        }
      )
      .test('email-unique', 'createAccountForm.field.mail.alreadyRegistered', async (value, context) => {
        if (context.parent.email && Yup.string().email().isValidSync(context.parent.email)) {
          try {
            const exists = await selfOnboardingService.existsEmail(value)
            return !exists?.email
          } catch (error) {
            return true
          }
        }
        return true
      }),
    password: Yup.string()
      .matches(minCharacters, {
        message: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minCharacters',
        excludeEmptyString: true
      })
      .matches(minLetters, {
        message: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minLetters',
        excludeEmptyString: true
      })
      .matches(minNumbers, {
        message: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minNumbers',
        excludeEmptyString: true
      })
      .matches(minSpecialCharacters, {
        message: 'selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minCharacterSpecial',
        excludeEmptyString: true
      })
      .required(requiredMsg)
  })
}
