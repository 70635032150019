import { RxLink1 } from 'react-icons/rx'
import { defineType } from 'sanity'

export default defineType({
  type: 'object',
  name: 'linkBox',
  title: 'LinkBox',
  icon: RxLink1,
  i18n: true,
  fields: [
    {
      type: 'array',
      name: 'columns',
      title: 'LinkBox columns',
      of: [
        {
          type: 'object',
          name: 'column',
          title: 'Column',
          fields: [
            {
              type: 'string',
              name: 'title',
              title: 'Title',
              description: 'Column title',
              validation: (Rule) => Rule.required()
            },
            {
              type: 'array',
              name: 'linkList',
              title: 'Link list',
              of: [
                {
                  type: 'object',
                  name: 'link',
                  title: 'Link',
                  fields: [
                    { type: 'string', name: 'label', title: 'Label', validation: (Rule) => Rule.required() },
                    { type: 'string', name: 'url', title: 'Url', validation: (Rule) => Rule.required() }
                  ]
                }
              ],
              validation: (Rule) => Rule.required()
            }
          ]
        }
      ],
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {},
    prepare({}) {
      return {
        title: 'LinkBox'
      }
    }
  }
})
