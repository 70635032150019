var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Xxejyxpy6HB_JhrewnwjU"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENV,
  dsn: SENTRY_DSN || 'https://243d03dbea45462399d71335eb91d220@o268373.ingest.sentry.io/5784528',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})
