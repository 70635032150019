import Head from 'next/head'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

const BreadcrumbSchema = () => {
  const router = useRouter()
  const { formatMessage } = useIntl()

  const baseUrl = process.env.NEXT_PUBLIC_URL
  const pathSegments = router.asPath.split('?')[0].split('/').filter(Boolean)

  const breadcrumbs = [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Partners Rappi',
      item: `${baseUrl}/${router.locale}`
    },
    ...pathSegments.map((segment, index) => ({
      '@type': 'ListItem',
      position: index + 2,
      name: segment.includes('blog')
        ? formatMessage({ id: 'landingPage.header.restaurant.blog' })
        : decodeURIComponent(segment),
      item: `${baseUrl}/${router.locale}/${pathSegments.slice(0, index + 1).join('/')}`
    }))
  ]

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs
          })
        }}
      />
    </Head>
  )
}

export default BreadcrumbSchema
