import { GTM_EVENTS, HOTJAR_PAGE_TAGS } from '@/constants/HotjarGTMConstants'
import {
  MESSAGES,
  OTP_CODE_LENGTH,
  PRIVACY_POLICY_LINKS,
  SIGNUP_ERRORS_MSG,
  TERMS_AND_CONDITIONS_LINKS,
  TIME_TO_WAIT_FOR_RESEND
} from '@/constants/SignupConstants'
import { errorSignup } from '@/constants/sentryErrors'
import SelfOnboardingService from '@/services/SelfOnboardingService'
import { captureError } from '@/utils/SentryUtilities'
import { sendTagManager, sendTagManagerEvent, sendTagManagerHotjar } from '@/utils/tagManager'
import { Button, CircularProgress, Typography } from '@mui/material'
import { ErrorMessage, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import OtpInput from '../otp-input/OtpInput'
import useStyles from './PhoneVerificationCode.styles'

const PhoneVerificationCodeForm = ({
  userData,
  goToCreateAccount,
  goToEmailVerificationCode,
  setSnackBarOpen,
  setSnackBarMsg,
  setUserData
}) => {
  const selfOnboardingService = new SelfOnboardingService()
  const { classes } = useStyles()
  const { formatMessage } = useIntl()
  const [attempts, setAttempts] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [timeSent, setTimeSent] = useState(TIME_TO_WAIT_FOR_RESEND)
  const [validSent, setValidSent] = useState()
  const hasBeenResend = attempts > 0
  const { phonePrefix = '', phone = '', country } = userData || {}

  useEffect(() => {
    if (!hasBeenResend) {
      sendCodeWhatsApp()
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSent((prevCounter) => {
        if (prevCounter === 1) return setValidSent(false)
        return prevCounter - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [validSent])

  const sendCodeWhatsApp = () => {
    if (!hasBeenResend) sendTagManagerEvent('signup-phone-verification-code-sent')
    setTimeSent(TIME_TO_WAIT_FOR_RESEND)
    setValidSent(true)
    setAttempts((attempts) => attempts + 1)

    const userInfo = {
      ...userData,
      phone: `${phonePrefix}${phone}`
    }

    sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeSent, HOTJAR_PAGE_TAGS.signup)

    selfOnboardingService
      .createSmsCode(userInfo)
      .then(() => sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeSuccess, HOTJAR_PAGE_TAGS.signup))
      .catch((error) => {
        const errorMessage = error?.response?.data
        sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeError, HOTJAR_PAGE_TAGS.signup)

        if (typeof errorMessage === 'string' && errorMessage.includes(MESSAGES.TO_MANY_REQUEST)) {
          setSnackBarOpen(true)
          setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.MULTIPLE_REQUEST }))
          return
        }

        setSnackBarOpen(true)
        setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.ERROR }))
      })

    if (hasBeenResend) {
      sendTagManager(GTM_EVENTS.verificationCode, userInfo)
    }
  }

  const handleSubmit = async (values, formikHelper) => {
    setLoading(true)
    const userValidatePhone = {
      ...userData,
      phone: `${phonePrefix}${phone}`,
      accessCodePhone: values.verificationCode.join('')
    }

    try {
      const isCodeValid = await selfOnboardingService.validateCodePhone(userValidatePhone)

      if (!isCodeValid) {
        formikHelper.setFieldError('verificationCode', formatMessage({ id: SIGNUP_ERRORS_MSG.INVALID_CODE }))
        return
      }

      sendTagManagerEvent('signup-phone-verification-code-success')
      sendTagManagerEvent('signup-email-verification-code-sent')
      setUserData(userValidatePhone)
      goToEmailVerificationCode()
    } catch (error) {
      const errorMessage = error?.response?.data
      formikHelper.setFieldError('verificationCode', errorMessage?.message)
      captureError(errorSignup, error?.message, userValidatePhone)
      setSnackBarOpen(true)
      setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.ERROR }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{ verificationCode: Array(OTP_CODE_LENGTH).fill('') }}
      onSubmit={(values, formikHelper) => {
        handleSubmit(values, formikHelper)
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit} className={classes.verificationCodeForm}>
          <div className={classes.otpBox}>
            <OtpInput
              name='verificationCode'
              placeholder='X'
              valuesCodes={values.verificationCode}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          </div>

          <ErrorMessage
            name='verificationCode'
            component={() => <div className={classes.otpErrorMessage}>{errors.verificationCode}</div>}
          />

          <Button
            fullWidth
            disabled={validSent}
            variant='text'
            className={classes.resendButton}
            onClick={() => sendCodeWhatsApp()}
          >
            <FormattedMessage
              id={
                validSent
                  ? 'selfOnboardingPage.landingPage.hero.verificationCodeForm.otp.resend.disabled'
                  : 'selfOnboardingPage.landingPage.hero.verificationCodeForm.otp.resend'
              }
              values={{ seconds: timeSent }}
            />
          </Button>

          <Typography variant='body1' className={classes.agreement}>
            <FormattedMessage
              id='selfOnboardingPage.landingPage.hero.phoneVerificationCodeForm.agreement'
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                terms: (chunks) => (
                  <a href={TERMS_AND_CONDITIONS_LINKS[country || 'CO']} target='_blank'>
                    {chunks}
                  </a>
                ),
                privacy: (chunks) => (
                  <a href={PRIVACY_POLICY_LINKS[country || 'CO']} target='_blank'>
                    {chunks}
                  </a>
                )
              }}
            />
          </Typography>

          <Button
            fullWidth
            type='submit'
            disabled={values.verificationCode.some((value) => value === '')}
            variant='contained'
            className={classes.submitButton}
          >
            {isLoading ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <FormattedMessage id='selfOnboardingPage.landingPage.hero.phoneVerificationCodeForm.button.submit' />
            )}
          </Button>

          <Button variant='text' className={classes.backButton} onClick={goToCreateAccount}>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.phoneVerificationCodeForm.button.back' />
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default PhoneVerificationCodeForm
