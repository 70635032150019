import Auth from '@/auth/Auth'
import { SOB_SIGNUP_SENT } from '@/constants/EventsAmplitudeConstatns'
import { GTM_EVENTS, HOTJAR_PAGE_TAGS } from '@/constants/HotjarGTMConstants'
import {
  MESSAGES,
  OTP_CODE_LENGTH,
  PRIVACY_POLICY_LINKS,
  SIGNUP_ERRORS_MSG,
  TERMS_AND_CONDITIONS_LINKS,
  TIME_TO_WAIT_FOR_RESEND
} from '@/constants/SignupConstants'
import { errorSignup, errorSignupEmailExist } from '@/constants/sentryErrors'
import { amplitude } from '@/hooks/useAmplitudeInit'
import SelfOnboardingService from '@/services/SelfOnboardingService'
import { captureError } from '@/utils/SentryUtilities'
import { sendTagManager, sendTagManagerEvent, sendTagManagerHotjar } from '@/utils/tagManager'
import { Button, CircularProgress, Typography } from '@mui/material'
import CryptoJS from 'crypto-js'
import { ErrorMessage, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import OtpInput from '../otp-input/OtpInput'
import useStyles from './EmailVerificationCode.styles'

const EmailVerificationCodeForm = ({ userData, goToCreateAccount, setSnackBarOpen, setSnackBarMsg }) => {
  const { classes } = useStyles()
  const { formatMessage } = useIntl()
  const router = useRouter()
  const selfOnboardingService = new SelfOnboardingService()
  const [attempts, setAttempts] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [timeSent, setTimeSent] = useState(TIME_TO_WAIT_FOR_RESEND)
  const [validSent, setValidSent] = useState()
  const hasBeenResend = attempts > 0

  const { country } = userData || {}

  useEffect(() => {
    setTimeSent(TIME_TO_WAIT_FOR_RESEND)
    setValidSent(true)
    setAttempts((attempts) => attempts + 1)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSent((prevCounter) => {
        if (prevCounter === 1) return setValidSent(false)
        return prevCounter - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [validSent])

  const sendCodeEmail = async () => {
    setTimeSent(TIME_TO_WAIT_FOR_RESEND)
    setValidSent(true)
    setAttempts((attempts) => attempts + 1)

    const userInfo = { ...userData }

    sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeSent, HOTJAR_PAGE_TAGS.signup)

    await selfOnboardingService
      .validateCodePhone(userInfo)
      .then(() => sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeSuccess, HOTJAR_PAGE_TAGS.signup))
      .catch(() => {
        setSnackBarOpen(true)
        setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.ERROR }))
        sendTagManagerHotjar(GTM_EVENTS.signupVerificationCodeError, HOTJAR_PAGE_TAGS.signup)
      })

    if (hasBeenResend) {
      sendTagManager(GTM_EVENTS.verificationCode, userInfo)
    }
  }

  const redirectLogin = async (email, password) => {
    const userLogin = { email, password }

    const encryptDataUser = CryptoJS.AES.encrypt(
      JSON.stringify(userLogin),
      process.env.NEXT_PUBLIC_AUTHENTICATE_SECRET_KEY
    ).toString()

    new Auth().InitEmbeddedLogin(email, password, encryptDataUser)
  }

  const handleSubmit = async (values, formikHelper) => {
    setLoading(true)
    const userInfo = {
      ...userData,
      accessCodeEmail: values.verificationCode.join('')
    }

    try {
      const isCodeValid = await selfOnboardingService.validateCodeEmail(userInfo)

      if (isCodeValid) {
        sendTagManagerEvent('signup-email-verification-code-success')
        sendTagManager('signup-sent', { email: userInfo.email })
        await selfOnboardingService
          .signup(userInfo)
          .then(async () => {
            sendTagManager(GTM_EVENTS.verificationCode, userInfo)
            sendTagManagerHotjar(GTM_EVENTS.signupFormSuccess, HOTJAR_PAGE_TAGS.signup)
            amplitude.track(SOB_SIGNUP_SENT, { RESPONSE: 'SUCCESS' })
            sendTagManager('signup-success', { email: userInfo.email })

            await redirectLogin(userInfo.email, userInfo.password)
          })
          .catch((error) => {
            const errorMessage = error?.response?.data?.message
            captureError(errorSignup, error?.message, userData)

            if (typeof errorMessage === 'string' && errorMessage.includes(MESSAGES.ALREADY_EXISTS)) {
              setSnackBarOpen(true)
              setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.ALREADY_EXISTS }))
              captureError(errorSignupEmailExist, SIGNUP_ERRORS_MSG.ALREADY_EXISTS, userData)
            } else {
              setSnackBarOpen(true)
              setSnackBarMsg(formatMessage({ id: SIGNUP_ERRORS_MSG.ERROR }))
            }

            setLoading(false)
          })
      } else {
        formikHelper.setFieldError('verificationCode', formatMessage({ id: SIGNUP_ERRORS_MSG.INVALID_CODE }))
        setLoading(false)
      }
    } catch (error) {
      formikHelper.setFieldError('verificationCode', error?.response?.data?.message)
      captureError(errorSignup, error?.message, userInfo)
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{ verificationCode: Array(OTP_CODE_LENGTH).fill('') }}
      onSubmit={(values, formikHelper) => {
        handleSubmit(values, formikHelper)
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit} className={classes.verificationCodeForm}>
          <div className={classes.otpBox}>
            <OtpInput
              name='verificationCode'
              placeholder='X'
              valuesCodes={values.verificationCode}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          </div>

          <ErrorMessage
            name='verificationCode'
            component={() => <div className={classes.otpErrorMessage}>{errors.verificationCode}</div>}
          />

          <Button
            fullWidth
            disabled={validSent}
            variant='text'
            className={classes.resendButton}
            onClick={() => sendCodeEmail()}
          >
            <FormattedMessage
              id={
                validSent
                  ? 'selfOnboardingPage.landingPage.hero.verificationCodeForm.otp.resend.disabled'
                  : 'selfOnboardingPage.landingPage.hero.verificationCodeForm.otp.resend'
              }
              values={{ seconds: timeSent }}
            />
          </Button>

          <Typography variant='body1' className={classes.agreement}>
            <FormattedMessage
              id='selfOnboardingPage.landingPage.hero.emailVerificationCodeForm.agreement'
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                terms: (chunks) => (
                  <a href={TERMS_AND_CONDITIONS_LINKS[country || 'CO']} target='_blank'>
                    {chunks}
                  </a>
                ),
                privacy: (chunks) => (
                  <a href={PRIVACY_POLICY_LINKS[country || 'CO']} target='_blank'>
                    {chunks}
                  </a>
                )
              }}
            />
          </Typography>

          <Button
            fullWidth
            type='submit'
            disabled={values.verificationCode.some((value) => value === '')}
            variant='contained'
            className={classes.submitButton}
          >
            {isLoading ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              <FormattedMessage id='selfOnboardingPage.landingPage.hero.emailVerificationCodeForm.button.submit' />
            )}
          </Button>

          <Button variant='text' className={classes.backButton} onClick={goToCreateAccount}>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.emailVerificationCodeForm.button.back' />
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default EmailVerificationCodeForm
